import get from 'lodash/get';
import { Redirect, Route } from 'react-router-dom';
import { parse } from 'src/_shared/services/utils.js';
import { USER_ROLES } from '../constants';
import { RouterContext } from '../contexts';

export function AuthenticatedRoute(props) {
	const { component: Component, roles = [] } = props;

	const rest = { ...props };
	delete rest.component;
	return (
		<RouterContext.Consumer>
			{({ auth, currentUser }) => {
				const role =
					localStorage.getItem('userDisplayAs') == ''
						? currentUser?.role
						: localStorage.getItem('userDisplayAs');

				const platformConfig = currentUser?.company?.platformConfig
					? JSON.parse(currentUser.company.platformConfig)
					: { primaryPlatform: 'referrals', filterMode: 'exclusionary', filterList: []};
				const checkFiltered = (filteredPage) => {
					if (currentUser?.role === USER_ROLES.SUPER_ADMIN) return false;
					return platformConfig?.filterList?.some((value) => value === filteredPage);
				};
				const redirectsArray = [
					{ filteredPage: 'points', paths: ['/giftcardstore', '/points'] },
					{ filteredPage: 'internal', paths: ['/myapplications', '/career', '/internal', '/internal-applicants', '/open', '/openDetails'] },
					{ filteredPage: 'jobs', paths: ['/browsejobs', '/jobs'] },
					{ filteredPage: 'referrals', paths: ['/my-referrals', '/referrals', '/myreferrals', '/mycontacts', '/deck'] },
					{ filteredPage: 'employees', paths: ['/employees', '/editprofile'] },
					{ filteredPage: 'settings', paths: ['/settings', '/form-builder', '/job-notification-email', '/branding', '/employee-dashboard-settings'] },
					{ filteredPage: 'bonuses', paths: ['/builder', '/bonuses', '/bonus-campaigns', '/mybonuses'] },
					{ filteredPage: 'messages', paths: ['/messagecenter'] },
					{ filteredPage: 'reports', paths: ['/report-builder'] },
					{ filteredPage: 'customPages', paths: ['/custompage'] },
					{ filteredPage: 'announcements', paths: ['/announcements'] },
					{ filteredPage: 'dashboard', paths: ['/dashboard'], redirectTo: currentUser?.role === USER_ROLES.ADMIN || currentUser?.role === USER_ROLES.SUPER_ADMIN ? '/recognitions-admin' : '/recognition-hub' }
				];

				return (
					<Route
						{...rest}
						render={(props) => {
							if (!auth) {
								return (
									<Redirect
										to={{
											pathname: '/login',
											state: {
												currentUser,
												redirectURL: props.location.pathname,
											},
										}}
									/>
								);
							}

							if (!currentUser) {
								return (
									<Redirect
										to={{
											pathname: '/logout',
											state: {
												redirectURL: props.location.pathname,
											},
										}}
									/>
								);
							}

							// These redirects are for the filterList that was set from Super Admin Console
							if (platformConfig.filterList.length > 0 && currentUser.role !== USER_ROLES.SUPER_ADMIN) {
								for (const { filteredPage, paths, redirectTo } of redirectsArray) {
									if (checkFiltered(filteredPage) && paths.some(path => props.location.pathname.startsWith(path))) {
										props.history.push(redirectTo || '/');
										return null;
									}
								}
							}

							if (
								(role === USER_ROLES.SUPER_USER ||
									role === USER_ROLES.SUPPORT_ADMIN) &&
								props.location.pathname === '/dashboard'
							) {
								props.history.push('/admin-console');
								return null;
							}

							const supportAdmin = parse(get(currentUser, 'admin'));
							const supportAdminPermissions =
								get(supportAdmin, 'permissions') === '*';
							if (
								role !== USER_ROLES.SUPER_USER &&
								role !== USER_ROLES.SUPPORT_ADMIN &&
								!supportAdminPermissions &&
								props.location.pathname === '/admin-console'
							) {
								props.history.push('/');
								return null;
							}

							// If corresponding manager permission type is set to "hidden"
							// and the user's role is manager, navigate to the app root
							if (role === USER_ROLES.MANAGER) {
								// Defines advanced manager permissions, if they've never been created
								// it assumes the default values.
								const disableManagerPermissionsByType = currentUser?.company
									?.disableManagerPermissionsByType
									? JSON.parse(
											currentUser.company.disableManagerPermissionsByType
										)
									: {
											managerReferrals: 'edit',
											managerJobs: 'edit',
											managerBonuses: 'hidden',
											managerEmployees: 'hidden',
										};
								if (
									(disableManagerPermissionsByType.managerBonuses ===
										'hidden' &&
										props.location.pathname === '/bonuses') ||
									(disableManagerPermissionsByType.managerEmployees ===
										'hidden' &&
										props.location.pathname.startsWith('/employees')) ||
									(disableManagerPermissionsByType.managerJobs === 'hidden' &&
										props.location.pathname.startsWith('/jobs')) ||
									(disableManagerPermissionsByType.managerReferrals ===
										'hidden' &&
										props.location.pathname.startsWith('/referrals'))
								) {
									props.history.push('/');
									return null;
								}
							}

							// If roles are specified and the current user's role isn't
							// included then navigate to the app root
							// NOTE: assumes no specified roles means any authenticated user
							// can access the route
							if (
								roles.length > 0 &&
								!roles.includes(role) &&
								!supportAdminPermissions
							) {
								props.history.push('/');
								return null;
							}

							return (
								<Component
									{...props}
									googleAuthClientID={get(rest, 'secrets.GoogleAuthClientID')}
								/>
							);
						}}
					/>
				);
			}}
		</RouterContext.Consumer>
	);
}
