import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import {
	queryWebNotificationsByUserIdIndex,
	updateUser,
} from '../../graphql/custom/users';

export const withGetUserNotifications = (Component) => {
	return compose(
		graphql(gql(queryWebNotificationsByUserIdIndex), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					userId: props.currentUser.id,
					nextToken: get(props, 'nextTokenNotifications', null),
				},
				pollInterval: 300_000,
				fetchPolicy: 'cache-and-network',
			}),
			props(response, previous) {
				const userNotifications = get(
					response,
					['data', 'queryWebNotificationsByUserIdIndex', 'items'],
					get(previous, 'userNotifications', [])
				);
				const nextTokenNotifications = get(
					response,
					[
						'data',
						'queryWebNotificationsByUserIdIndex',
						'nextTokenNotifications',
					],
					null
				);
				const onFetchMoreNotifications = makeOnFetchMore(
					response.data.fetchMore,
					nextTokenNotifications
				);
				return {
					currentUser: response.ownProps.currentUser,
					userNotifications,
					onFetchMoreNotifications,
					nextTokenNotifications,
				};
			},
		}),
		graphql(gql(updateUser), {
			props: (props) => ({
				onUpdate(input) {
					props.mutate({
						variables: input,
						fetchPolicy: 'no-cache',
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMore = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return { ...previous };
				}

				const previousItems = previous.queryWebNotificationsByUserIdIndex.items
					? previous.queryWebNotificationsByUserIdIndex.items
					: [];
				const moreItems = fetchMoreResult.queryWebNotificationsByUserIdIndex
					.items
					? fetchMoreResult.queryWebNotificationsByUserIdIndex.items
					: [];
				return {
					...previous,
					queryWebNotificationsByUserIdIndex: {
						...previous.queryWebNotificationsByUserIdIndex,
						...fetchMoreResult.queryWebNotificationsByUserIdIndex,
						items: [...previousItems, ...moreItems],
					},
				};
			},
		});
	};
};
