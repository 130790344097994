import { useState, useEffect } from 'react';
import CompanyCard from './addCompanyCard/CompanyCardComponent.jsx';
import { Switch, Transfer, Button, message } from 'antd';

export function SuperUser(props) {
	const {
		company,
		onCreateUser,
		onCreateCompany,
		onCreateDepartment,
		currentUser,
		onCreateUserGroup,
		onUpdateCompany,
		updateCurrentUserCompany,
	} = props;

	const defaultConfig = JSON.stringify({
		primaryPlatform: "referrals",
		filterMode: "exclusionary",
		filterList: []
	});

	const PlatformConfigComponent = ({ initialConfig }) => {
		const [platformConfig, setPlatformConfig] = useState(JSON.parse(initialConfig));

		const transferOptions = [
			"dashboard", "jobs", "reports", "referrals", "internal",
			"bonuses", "messages", "points", "employees",
			"settings", "announcements", "customPages"
		];

		const transferDataSource = transferOptions.map(item => ({
			key: item,
			title: item,
		}));

		const handlePlatformToggle = (checked) => {
			setPlatformConfig({
				...platformConfig,
				primaryPlatform: checked ? "recognition" : "referrals"
			});
		};

		const handleTransferChange = (targetKeys) => {
			setPlatformConfig({
				...platformConfig,
				filterList: targetKeys
			});
		};

		const saveConfig = async () => {
			const input = { input: {id: company.id, platformConfig: JSON.stringify(platformConfig)} };
			const responseForStoreUpdate = await onUpdateCompany(input);
			if (responseForStoreUpdate?.data?.updateCompany) {
				updateCurrentUserCompany(
					responseForStoreUpdate.data.updateCompany
				);
				message.success('Company updated successfully!');
			} else {
				message.error('Sorry, something went wrong.')
			}
		};

		return (
			<div>
				<div style={{marginBottom: 16}}>
					<span>Set Primary Platform to "Recognition": </span>
					<Switch
						checked={platformConfig.primaryPlatform === "recognition"}
						onChange={handlePlatformToggle}
					/>
				</div>


				<Transfer
					dataSource={transferDataSource}
					targetKeys={platformConfig.filterList}
					listStyle={{
						width: 300,
						height: 300,
					}}
					onChange={handleTransferChange}
					render={item => item.title}
					operations={["Disable", "Enable"]}
					titles={['Active Pages', 'Disabled Pages']}
				/>

				<Button style={{marginTop: 16}} type="primary" onClick={saveConfig}>Save Changes</Button>
			</div>
		);
	};


	return (
		<main>
			<div className="page-title">
				<h2 className="page-heading">Erin System Admin Console</h2>
			</div>
			<CompanyCard
				{...props}
				currentUser={currentUser}
				addCompany={onCreateCompany}
				addUser={onCreateUser}
				addDepartment={onCreateDepartment}
				addUserGroup={onCreateUserGroup}
			/>
			<div className="custom-card">
				<h5>Platform Configuration</h5>
				<PlatformConfigComponent initialConfig={company?.platformConfig || defaultConfig} />
			</div>
		</main>
);
}
